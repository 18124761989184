@import '~@enliven/scss/eh-colors';

* {
  font-family: 'Open Sans' !important;
}

input,
.rcw-message {
  line-height: 24px !important;
}

.rcw-previewer .rcw-message-img,
button {
  cursor: pointer;
}

input,
select,
span.ac-textRun,
.rcw-message-text p {
  font-size: 16px !important;
}

.rcw-conversation-container {
  &.active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  &.hidden {
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .rcw-header,
  .rcw-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: center;
    background-color: var(--primary-brand-color);
    color: var(--primary-brand-font-color);
  }

  .rcw-header {
    padding: 16px;
    height: 40px;
  }

  .rcw-title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: normal;
    color: var(--primary-brand-font-color);
  }

  .rcw-close-button {
    background-color: transparent;
    border: 2px solid $eh-gray-0;
    display: flex;
    position: absolute;
    right: 0px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin-right: 16px;
  }

  .rcw-close {
    width: 8px;
    height: 8px;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 0;
    margin-right: 12px;
    vertical-align: middle;
  }
}

.rcw-messages-container {
  padding: 24px;
  background-color: $eh-gray-0;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.rcw-full-screen {
  height: 100vh;
  margin: 0;
  max-width: none;
  width: 100%;

  .rcw-header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
  }

  .rcw-title {
    padding: 0;
  }

  .rcw-messages-container {
    height: 100%;
    max-height: none;
  }

  .rcw-conversation-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.rcw-message {
  margin-bottom: 16px;
  display: -ms-flexbox;
  display: flex;
  word-wrap: break-word;
}

/* empty wrapper div surrounding adaptive cards causes cards not to render full width. this fixes. */
.rcw-message > div:not([class]) {
  width: 100%;
}

.rcw-message-text {
  border-radius: 8px;
  text-align: left;
  padding: 8px 16px;
}

.rcw-message-text p {
  margin: 0;
}

.rcw-client,
.rcw-response {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rcw-client {
  margin-left: auto;

  .rcw-message-text {
    background-color: var(--tertiary-brand-color);
    color: var(--primary-brand-font-color);
  }

  .rcw-timestamp {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

.rcw-response {
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;

  .rcw-message-text {
    background-color: $eh-gray-2;
    color: $eh-gray-5;
  }
}

.rcw-avatar {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.rcw-snippet {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}

.rcw-snippet-title {
  margin: 0;
}

.rcw-snippet-details {
  border-left: 2px solid #35e65d;
  margin-top: 5px;
  padding-left: 10px;
}

.rcw-sender {
  -ms-flex-align: center;
  align-items: center;
  display: none;
  background-color: #f4f7f9;
  height: 0px;
  padding: 0px;
  border-radius: 0 0 10px 10px;
  visibility: hidden;
}

.rcw-sender.expand {
  height: 55px;
}

.rcw-sender .rcw-picker-btn {
  display: none;
}

.rcw-new-message {
  width: 100%;
  border: 0;
  background-color: #f4f7f9;
  height: 30px;
  padding-left: 15px;
  resize: none;
}

.rcw-new-message:focus {
  outline: none;
}

.rcw-new-message.expand {
  height: 40px;
}

.rcw-send {
  background: #f4f7f9;
  border: 0;
}

.rcw-send .rcw-send-icon {
  height: 25px;
}

.rcw-launcher {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: d;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: d;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: d;
  animation-fill-mode: forwards;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  background-color: var(--primary-brand-color);
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;

  &:focus {
    outline: none;
  }

  .rcw-badge {
    position: fixed;
    top: -10px;
    right: -5px;
    background-color: red;
    color: $eh-gray-0;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
  }
}

.rcw-open-launcher {
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: c;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: c;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: c;
  animation-fill-mode: forwards;
}

.rcw-close-launcher {
  width: 20px;
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: b;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 0.5s;
  -moz-animation-name: b;
  -moz-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: b;
  animation-fill-mode: forwards;
}

.rcw-previewer-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;

  .rcw-previewer-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.3s ease;
  }

  .rcw-previewer-tools {
    position: fixed;
    right: 16px;
    bottom: 16px;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .rcw-previewer-button,
  .rcw-previewer-tools {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .rcw-previewer-button {
    padding: 0;
    margin: 16px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    outline: none;
    background-color: $eh-gray-0;
    border: none;
  }

  .rcw-previewer-veil {
    width: 100%;
    height: 100%;
    overflow: scroll;
    position: relative;
  }

  .rcw-previewer-close-button {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@keyframes b {
  0% {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes c {
  0% {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes d {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chatbot-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  max-width: 480px;
  width: 100%;
  z-index: 9999;
  box-shadow: 0 2px 10px 1px #b5b5b5;

  .rcw-widget-container,
  .rcw-conversation-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 25px;
    max-width: 480px;
    width: 100%;
    z-index: 9999;
  }
}

.chatbot-footer {
  z-index: 10000;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 480px;
  height: fit-content;
  position: fixed;
  padding: 5px;
  color: #595959; // matches eh-colors v1.3.0 $eh-gray-8
  font-size: 12px;
  text-align: right;
  right: 20px;

  a {
    color: #000000;
    font-size: 12px;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-right: 25px;
    }
  }
}

.chatbot-container-closed {
  box-shadow: none !important;
}

button:focus {
  outline: none;
}

.header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  position: absolute;
  height: 24px;
  right: 41px;
}

.rcw-header-button {
  border: 0;
  margin-right: 16px;
  background: $eh-primary-blue;
  color: $eh-gray-0;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* SmartPhones */
@media screen and (max-height: 480px), (max-width: 480px) {
  input,
  select,
  span.ac-textRun,
  .rcw-message-text p {
    font-size: 4vw !important;
  }

  .rcw-conversation-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;

    .rcw-header {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      position: relative;
    }
  }

  .rcw-widget-container {
    margin: 0;
    max-width: none;
    width: 100%;

    &:not(.rcw-close-widget-container) .rcw-launcher {
      display: none;
    }

    &:not(.rcw-close-widget-container) .rcw-title {
      margin-right: 32px;
    }
  }

  .rcw-messages-container {
    max-height: none;
  }

  .rcw-hide-sm {
    display: none;
  }

  .rcw-sender {
    border-radius: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}
