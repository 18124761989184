@import '~@enliven/scss/eh-colors';

/* Styles related to quick button overrides should be put in this file */
.quick-button {
  background: none;
  border-radius: 15px;
  border: 2px solid #35cce6;
  font-weight: 700;
  padding: 5px 10px;
  outline: 0;

  &:active {
    background: #02235c;
    color: $eh-gray-0;
  }
}

.quick-buttons-container {
  background: $eh-gray-0;
  white-space: nowrap;
  padding: 10px;

  .quick-buttons {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    .quick-list-button {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

/* SmartPhones */
@media screen and (max-height: 480px), (max-width: 480px) {
  .quick-buttons-container {
    padding-bottom: 15px;
  }
}
