@import '~@enliven/scss/eh-colors';

.ac-container.ac-adaptiveCard {
  padding: 0 !important;
}

.ac-vertical-separator {
  width: 16px !important;
}

.ac-horizontal-separator {
  height: 16px !important;
  margin-bottom: 0px !important;
  visibility: hidden;
}

span.ac-textRun {
  color: #303030 !important;
}

.ac-pushButton {
  height: 48px;
  background-color: var(--primary-brand-color);
  color: var(--primary-brand-font-color);
  border-radius: 8px;
  font-size: 18px;
  min-width: 100%;
  border: none;

  &:hover {
    background-color: var(--secondary-brand-color);
    border: none;
  }

  &:focus {
    background-color: var(--primary-brand-color);
    border: 4px solid var(--tertiary-brand-color);
  }
}

option:checked {
  background-color: var(--primary-brand-color);
  color: var(--primary-brand-font-color);
}

.ac-input-container {
  > input,
  > select {
    border: 1px solid $eh-gray-4;
    border-radius: 8px;
    padding: 8px;
    color: #303030;

    &:focus {
      outline: none;
      border: 1px solid var(--tertiary-brand-color);
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    height: 24px;
    width: 24px;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid var(--primary-brand-color);
    border-radius: 6px;

    &:checked {
      background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='20' height='20' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>")
        no-repeat;
    }

    &:hover {
      border: 4px solid var(--secondary-brand-color);

      &:is(:checked) {
        background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='15' height='15' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>")
          no-repeat;
      }
    }
  }

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid var(--primary-brand-color);
    border-radius: 50%;

    &:checked {
      background-color: var(--primary-brand-color);
      border: 4px solid var(--secondary-brand-color);
    }

    &:hover {
      border: 4px solid var(--primary-brand-color);
    }
  }

  > .ac-input-validation-failed,
  > .ac-input-validation-failed:focus {
    border: 1px solid var(--error-brand-color);
  }
}

.ac-choiceSetInput-multiSelect,
.ac-choiceSetInput-expanded,
.ac-choiceSetInput-multiSelect div + div,
.ac-choiceSetInput-expanded div + div {
  margin-top: 8px;
}

.button.ac-pushButton:active {
  background: $eh-primary-blue;
  color: $eh-gray-0;
}
