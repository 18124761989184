/* Styles related to loading dot overrides should be put in this file */
.loader {
  margin-bottom: 16px;
  display: none;

  &.active {
    display: -ms-flexbox;
    display: flex;
  }
}

.loader-container {
  background-color: #f4f7f9;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}

.loader-dots {
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: gray;
  margin-right: 2px;
  animation: a 0.5s ease infinite alternate;

  &:first-child {
    animation-delay: 0.2s;
  }

  &:nth-child(2) {
    animation-delay: 0.3s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes a {
  0% {
    transform: translateY(0);
  }

  to {
    transform: translateY(5px);
  }
}
